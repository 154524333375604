@value unit: var(--ring-unit);

.heading {
  display: flex;
  align-items: baseline;
}

.headingPieChart {
  margin: auto 0;
  margin-right: calc(unit / 2);
}

.actions {
  display: flex;
  align-items: baseline;

  margin-top: unit;

  line-height: var(--ring-line-height);
}

.zoom,
.adaptScale {
  margin-right: calc(unit * 1.5);
}

.filter {
  display: flex;
  align-items: baseline;

  margin-left: auto;
}

.agentFilterPopup {
  z-index: 10;
}

.showAverage,
.showFailedCheckbox,
.showPersonalBuildsCheckbox {
  margin-right: calc(unit * 1.5);
}

.subheadingSuccess {
  color: var(--ring-success-color);
}

.subheadingFailure {
  color: var(--ring-error-color);
}

.subheadingIgnored {
  color: var(--ring-secondary-color);
}

.statisticCounts {
  display: flex;

  margin-left: calc(unit * 0.5);

  & > div {
    margin-right: calc(unit * 1.5);

    color: var(--ring-secondary-color);

    font-size: var(--ring-font-size-smaller);
  }
}

.statisticFailure {
  display: flex;
  align-items: baseline;
}

.statisticFailureIcon {
  position: relative;

  width: 11px;
  height: 14px;
  margin: auto;
  margin-right: calc(unit / 2);

  &::before {
    position: absolute;
    top: 0;
    left: 0;

    width: 2px;
    height: 100%;

    content: '';

    opacity: 0.2;
    background-color: var(--ring-error-color);
    box-shadow:
      0 0 0 0 var(--ring-error-color),
      3px 0 0 0 var(--ring-error-color),
      6px 0 0 0 var(--ring-error-color),
      9px 0 0 0 var(--ring-error-color);
  }
}

.snippet {
  display: flex;
  align-items: baseline;
  flex: 1;
  justify-content: flex-end;
}

.help {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.download,
.help {
  margin-right: calc(unit * 1.5);
  padding: 0;

  &:last-child {
    margin-right: 0;
  }
}

.anchor {
  height: unset;

  color: inherit;

  line-height: inherit;
}

.dropdown {
  margin-right: -10px;
}

.popup {
  position: relative;

  width: calc(100 * unit);
  min-height: 350px;
  padding: calc(unit * 2);
}

.fewLaunches {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.popupChart {
  margin: 0;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.loaderLabel {
  margin-top: calc(unit * 2);
}
