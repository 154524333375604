@value unit: var(--ring-unit);

.tile {
  position: absolute;

  overflow: hidden;
}

.left {
  margin-left: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-weight: normal;
}

.artifacts {
  height: calc(unit * 2);
  margin-top: calc(unit / -2);
  padding: 0 calc(unit / 2);
}

.running,
.running svg {
  color: var(--ring-main-color);
}

.failure {
  color: var(--ring-error-color);

  & svg {
    color: var(--ring-icon-error-color);
  }
}
