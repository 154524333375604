@value unit: var(--ring-unit);

.wrapper {
  position: relative;
}

.edges {
  position: relative;
  fill: transparent;

  stroke: rgba(var(--ring-borders-components), 0.7);

  pointer-events: none;
}
