@value unit: var(--ring-unit);
@value font-smaller-lower from '../../../../../../ring-globals.css';

.button {
  composes: font-smaller-lower;

  margin-right: unit;
  margin-bottom: unit;
  padding: 0;

  color: var(--ring-secondary-color);
}
