.title {
  display: flex;
  justify-content: space-between;

  letter-spacing: 1px;

  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lower);
}

.button {
  display: flex;
  align-items: center;

  height: var(--ring-line-height-lower);

  & .icon {
    color: var(--ring-main-color);
  }
}
