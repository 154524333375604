@value unit: var(--ring-unit);

.container {
  position: absolute;
}

.label {
  margin-left: calc(unit / 2);

  color: var(--ring-main-color);
}

.icon {
  & svg {
    width: calc(unit * 2);
    height: calc(unit * 2);
  }
}

.default {
  color: var(--ring-icon-color);
}

.selected {
  color: var(--ring-main-color);
}

.error {
  color: var(--ring-error-color);
}

.deleted {
  color: var(--ring-disabled-color);
}

.errorMessage {
  color: var(--ring-error-color);
}
