@value unit: var(--ring-unit);

.alert {
  display: flex;
  column-gap: unit;
}

.badges {
  display: flex;
  gap: calc(unit / 2);

  flex-wrap: wrap;
}

.value {
  font-size: var(--ring-font-size);
  font-weight: 600;
  line-height: var(--ring-line-height);
}

.link {
  height: max-content;

  white-space: nowrap;

  color: var(--ring-main-color);

  font-weight: normal;
}

.learnMore {
  color: var(--ring-secondary-color);
}

.buyMore {
  margin-left: unit;
}

.icon {
  margin-right: calc(unit / 2);
}
