@value unit: var(--ring-unit);

.name {
  margin-bottom: calc(unit * 0.25);

  line-height: var(--ring-line-height);
}

.newFailure {
  font-weight: bold;
}

.status {
  display: flex;
  align-items: baseline;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.icon {
  margin-right: calc(unit / 4);

  color: var(--ring-icon-error-color);
}

.text {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.full {
  white-space: normal;
  overflow-wrap: break-word;
}
