.error {
  color: var(--ring-error-color);
}

.iconButtonClose {
  position: absolute;

  top: 0;
  right: 0;
}
