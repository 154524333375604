@value unit: var(--ring-unit);

.path {
  display: flex;

  overflow: hidden;
  flex-direction: row-reverse;

  flex-wrap: wrap;
  justify-content: flex-end;

  height: 22px;
  margin: 0 -2px;
  padding: 0 2px;
}

.pathItem {
  white-space: nowrap;
}

.parentItemContainer {
  z-index: 1;

  margin-right: calc(-2 * unit);

  background: var(--ring-content-background-color);
}

.targetItem {
  overflow: hidden;

  min-width: 0;
  margin: 0 -2px;
  padding: 0 2px;

  text-overflow: ellipsis;
}

.ellipsis {
  width: calc(1.5 * unit);

  cursor: default;
}

.ellipsis,
.separator {
  margin-left: calc(0.5 * unit);
}

.parentsDropDownAnchor {
  display: inline;
}

.parentsPopup {
  padding: unit calc(2 * unit);

  color: var(--ring-secondary-color);
}

.separator {
  color: var(--ring-secondary-color);
}

.link {
  color: var(--ring-text-color);
}

.parentItem {
  color: var(--ring-secondary-color);
}

.icon {
  margin: 0 calc(0.5 * unit);

  color: var(--ring-icon-secondary-color);
}

.icon :global(.secondary) {
  fill: var(--ring-border-disabled-color);
}
