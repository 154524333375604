@value unit: var(--ring-unit);

.inactiveKeys {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 1.5);
}

.heading.heading {
  margin: 0;

  font-size: 16px;
  font-weight: 700;
}

.toggle {
  padding-right: 0;
  padding-left: 0;

  color: inherit;

  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.checkbox {
  left: 4px;
}

.licenseKeysTable {
  composes: licenseKeysTable from '../ActiveLegacyLicenses/ActiveLegacyLicenses.css';

  width: 100%;

  & td:first-child,
  & th:first-child {
    width: 40%;
  }

  & td:last-child,
  & th:last-child {
    width: 0%;
  }
}

.warn {
  color: var(--ring-error-color);
}
