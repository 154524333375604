@value unit: var(--ring-unit);

.valuesContainer {
  display: flex;
  flex: 1;
  flex-direction: column;

  width: 100%;

  margin-top: calc(unit - unit / 4);
}

.values.values {
  display: flex;
  flex-wrap: nowrap;
}

.value {
  display: flex;
  flex: 1;
  justify-content: center;

  padding-top: 1px;

  font-family: 'Twemoji Mozilla', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji', 'EmojiOne Color', 'Android Emoji', sans-serif;
}

.hints {
  display: flex;
  justify-content: space-between;

  padding: calc(unit / 4) 0;
}

.hint {
  display: block;

  margin: 0;
  padding: 0;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}
