@value ellipsis from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.column {
  position: relative;

  padding: calc(unit / 2) unit;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.columnValue {
  composes: ellipsis;

  height: var(--ring-line-height);

  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.parameterName,
.parameterValue {
  padding-right: calc(unit * 2);

  white-space: nowrap;

  font-weight: 600;
}

.header.parameterName .columnValue,
.body.parameterValue .columnValue {
  max-width: 150px;
}

.row {
  display: contents;

  &:hover {
    /* stylelint-disable-next-line selector-max-specificity */
    & .parameterValue .columnValue {
      opacity: 1;
    }
  }
}

.column:hover::after,
.column:hover::before {
  position: absolute;
  z-index: -1;

  content: '';

  opacity: 0;
}

.column:hover::after {
  top: -100vh;
  left: 0;

  width: 100%;
  height: 200vh;
}

.column:hover::before {
  top: 0;
  left: -100vw;

  width: 200vw;
  height: 100%;
}

.column:hover {
  /* stylelint-disable-next-line selector-max-specificity */
  &.build {
    background-color: var(--ring-hover-background-color);
  }

  /* stylelint-disable-next-line selector-max-specificity */
  &.build::after,
  &.build::before,
  &.header.parameterValue::after,
  &.body.parameterValue::before {
    opacity: 0.3;
    background-color: var(--ring-hover-background-color);
  }
}

.row.withUnderline .column {
  border-bottom: 1px solid var(--ring-line-color);
}

.header {
  border-top: 1px solid var(--ring-line-color);
  border-bottom: 1px solid var(--ring-line-color);

  &.parameterName {
    color: var(--ring-secondary-color);

    font-weight: normal;
  }
}
