@value unit: var(--ring-unit);

.noResults {
  padding: unit calc(unit * 1.5) calc(unit) calc(unit * 4);
}

.noResultsMessage {
  word-break: break-word;

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height);
}
