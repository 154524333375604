/* stylelint-disable selector-max-specificity, selector-id-pattern */
@value font from '@jetbrains/ring-ui/components/global/global.css';

.app {
  --ring-font-size: 14px;

  composes: font;
}

.app,
.popupTarget {
  display: flex;
  flex: 1;
  flex-direction: column;
}
