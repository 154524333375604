@value unit: var(--ring-unit);

.actions {
  display: flex;
  gap: calc(unit * 2);
}

.secondary {
  padding: 0;
}
