@value unit: var(--ring-unit);

.select {
  width: 100%;

  background-color: var(--ring-content-background-color);
}

.imageTag {
  color: var(--ring-secondary-color);
}

.imageLink {
  height: calc(unit * 2);
  margin-left: unit;
  padding: 0;

  color: var(--ring-icon-color);
}

.errorMessage,
.incorrectNameMessage,
.notFoundMessage {
  width: min-content;
  min-width: fit-content;
  padding: calc(unit * 1.5) calc(unit * 2);

  color: var(--ring-secondary-color);
}

.errorMessage {
  color: var(--ring-icon-error-color);
}

.notFoundMessageCurrentValue {
  word-break: break-all;

  color: var(--ring-text-color);
}
