.link {
  composes: link from '../Link/Link.css';
}

.button:hover {
  text-decoration: none;

  color: inherit;
}

.disabled {
  pointer-events: none;
}
