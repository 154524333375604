@value large-screen-media from '../../../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.changesFilter {
  width: 100%;
}

.line {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: flex-start;

  &:first-child {
    position: relative;
    z-index: 3;
  }
}

.filterControl {
  margin-right: var(--ring-unit);
  margin-bottom: var(--ring-unit);

  &:last-child {
    margin-right: 0;
  }
}

.commiterSelect,
.changesContentFilter {
  flex: 1;
}

.commiterSelectButton {
  width: 100%;
  max-width: 100%;
}

.jobDetailsPopupTarget {
  &.changesFilter {
    max-width: 1280px;
  }
}
