@value unit: var(--ring-unit);

.container {
  min-width: calc(unit * 25);
}

.wrapper {
  position: relative;

  /* stylelint-disable selector-max-specificity, selector-id-pattern */
  & :global(#SAKURA_AFTER_AGENT_INFO) {
    position: absolute;
    top: calc(unit / -4);
    right: calc(-1 * unit);

    & button {
      color: var(--ring-link-color);

      &:hover {
        color: var(--ring-link-hover-color);
      }
    }
  }
  /* stylelint-enable selector-max-specificity, selector-id-pattern */

  & .agentInfo {
    white-space: nowrap;

    font-size: var(--ring-font-size-smaller);
    line-height: var(--ring-line-height-lowest);
  }
}

.label {
  margin-bottom: 6px;

  color: var(--ring-secondary-color);
}
