@value font-smaller-lower  from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.reusedNote {
  margin-right: calc(unit * 2);

  white-space: nowrap;
}

.container {
  display: inline-block;

  min-width: 100%;
}

.panelContainer {
  position: sticky;
  z-index: 2;
  top: 0;
  left: calc(unit * -2);

  width: 0;

  margin: calc(unit * 0.5) calc(unit * -2) 0;
}

.panel {
  display: inline-block;

  padding: unit calc(unit * 2) calc(unit * 2);

  border-bottom-right-radius: var(--ring-border-radius);

  background-color: rgba(var(--ring-content-background-components), 0.9);
  backdrop-filter: blur(1px);
}

.projectWrapper {
  margin-left: calc(unit * -1);
  padding-left: unit;

  box-shadow: -1px 0 var(--ring-borders-color);
}

.selectedProjectWrapper {
  box-shadow: -2px 0 var(--ring-main-color);
}

.settings {
  display: flex;

  align-items: baseline;

  margin: 0 calc(unit * -1);

  white-space: nowrap;

  & > * {
    margin: 0 unit;
  }
}

.fullScreenButton {
  height: auto;
  margin: 0 calc(unit * -1) 0 auto;

  line-height: inherit;
}

.innerContainer {
  position: relative;

  margin-left: calc(unit * -2);
}

.chartContainer {
  display: inline-block;
}

.item {
  composes: font-smaller-lower;

  position: absolute;

  width: var(--tc-chain-item-width);
}

.child {
  position: static;

  margin-bottom: 5px;
}

.child.withDetails {
  margin: unit 0 calc(unit * 2);
}

.link {
  display: block;

  color: var(--ring-text-color);
}

.textWrapper {
  display: inline-block;

  max-width: 100%;

  border-radius: var(--ring-border-radius);

  background-color: rgba(var(--ring-content-background-components), 0.7);
  backdrop-filter: blur(1px);
}

span.textWrapper {
  display: inline;
}

.buildTypeDropdown {
  composes: textWrapper;

  margin-top: 2px;
}

.buildTypeAnchor {
  max-width: 100%;

  height: auto;

  text-align: start;

  white-space: nowrap;

  color: var(--ring-text-color);
}

.failed {
  color: var(--ring-error-color);
}

.canceled {
  color: var(--ring-secondary-color);
}

.queued {
  color: var(--ring-secondary-color);
}

.project {
  composes: textWrapper;

  color: var(--ring-secondary-color);
}

.projectTitle {
  margin: -6px 0 0 -2px;

  color: var(--ring-text-color);

  font-weight: bold;
}

.icon {
  position: relative;

  margin-right: calc(unit / 2);

  &::before {
    position: absolute;

    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;

    content: '';

    border-radius: 11px;

    background-color: var(--ring-content-background-color);
  }

  & > svg {
    position: relative;
    z-index: var(--ring-fixed-z-index);
  }
}

.runIconButtonWrapper {
  composes: icon;

  display: inline-block;
}

.runIconButton {
  position: relative;

  height: auto;

  padding: 0;

  line-height: normal;
}

.stack {
  composes: icon;

  margin-left: -16px;

  color: var(--ring-icon-color);
}

.highlightedSelectedIcon::before {
  top: -3px;
  right: -4px;
  bottom: -3px;
  left: -4px;

  box-shadow: 0 0 0 2px var(--ring-main-color) inset;
}

.highlightedSelectedIcon.withStack::before {
  right: -6px;
}

.highlightedStack {
  color: var(--ring-main-color);
}

.edge {
  stroke: var(--ring-borders-color);
  stroke-width: 0.75px;
}

.highlightedEdge {
  stroke: var(--ring-main-color);
  stroke-width: 2px;
}

.hidden {
  opacity: 0;
}

.builds {
  margin-top: calc(unit * 4);
}

.loader {
  padding-top: calc(unit * 12);
}

.popup {
  width: calc(unit * 40);
}

.path {
  composes: font-smaller-lower;

  white-space: normal;

  color: var(--ring-secondary-color);
}

.popup .path {
  margin: 0;
  padding: calc(unit - 2px) calc(unit * 2 - 2px);

  background-color: var(--ring-sidebar-background-color);
}

.runPopup {
  width: calc(unit * 30);
}

.build {
  box-sizing: border-box;
  height: calc(unit * 8);
  padding: unit calc(unit * 2);

  border-top: 1px solid var(--ring-line-color);
}

.buildTopRow {
  display: flex;
  align-items: baseline;

  margin: 0 calc(-0.5 * unit);

  & > * {
    margin: 0 calc(unit / 2);
  }
}

.buildBranch {
  min-width: 0;
}

.runBuild {
  margin-left: auto;
}

.unrelated {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  margin-bottom: 3px;

  border: 1.5px dashed var(--ring-borders-color);
  border-radius: 50%;
  background-color: var(--ring-content-background-color);
}

.buildTypeLabel {
  position: relative;
}

.selfBuildTypeLabel {
  font-weight: bold;
}

.buildTypeAnchor .buildTypeEllipsis {
  max-width: calc(var(--tc-chain-item-width) - 10px);
}

.buildTypeEllipsisComposite {
  max-width: calc(var(--tc-chain-item-width) - 16px);
}

.buildTypeAnchor .buildTypeEllipsisComposite {
  max-width: calc(var(--tc-chain-item-width) - 26px);
}

.buildTypeAnchor .buildTypeEllipsisChild {
  max-width: calc(var(--tc-chain-item-width) - 28px);
}

.buildTypeAnchor .buildTypeEllipsisComposite.buildTypeEllipsisChild {
  max-width: calc(var(--tc-chain-item-width) - 44px);
}

.ungroupButton {
  height: auto;
  margin-left: calc(unit * -2);

  line-height: var(--ring-line-height-lower);
}

.groupUngroupButton {
  width: 100%;
  height: auto;

  border-bottom: 1px solid var(--ring-line-color);

  line-height: 32px;
}

.status {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  display: -webkit-box;
  overflow: hidden;
}

.progress {
  margin-bottom: calc(unit / 2);
}

.branch {
  min-width: 0;
  margin-top: calc(unit / 2);
}

.artifacts {
  margin-left: calc(unit * -1);
}

.comment {
  margin-right: unit;
}

.artifactsBranch {
  display: flex;
  align-items: baseline;
}

.highlightReused .reused::before {
  top: -4px;
  right: -5px;
  bottom: -4px;
  left: -5px;

  background-color: rgba(237, 162, 0, 0.3);
}

.projectIcon {
  position: relative;
  z-index: var(--ring-fixed-z-index);
  top: -2px;
}

.projectIcon > svg {
  position: relative;
}

.projectIcon::before {
  position: absolute;
  top: -2px;
  left: -3px;

  width: calc(unit * 3);
  height: calc(unit * 3);

  content: '';

  border-radius: var(--ring-border-radius);

  background-color: var(--ring-content-background-color);
}

.selectedProjectIcon::before {
  box-shadow: inset 0 0 0 2px var(--ring-main-color);
}

.ungrouped {
  margin-top: calc(unit / 2);
}

.ungroupedLabel {
  margin-right: calc(unit / 2);

  font-weight: bold;
}

.ungroupedItem {
  display: inline-flex;
  align-items: baseline;
}

.ungroupedPath {
  white-space: nowrap;
}

.groupButton {
  padding: 0 unit;
}

.ungroupProjectButton {
  height: auto;
  margin-right: calc(unit * -1);
  padding: 0 unit;

  line-height: inherit;
}

.projectNameWrapper {
  display: flex;
  align-items: baseline;
}
