@value unit: var(--ring-unit);

.form {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 1.5);
}

.input {
  overflow-y: auto !important;

  width: calc(unit * 82);
  height: calc(unit * 10) !important;
}
