@value unit: var(--ring-unit);

.overlay {
  cursor: wait;

  opacity: 0.3;

  & > * {
    pointer-events: none;
  }
}

.loader {
  overflow: hidden;

  padding-top: unit;
}
