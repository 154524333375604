@value unit: var(--ring-unit);

.navigation {
  position: absolute;

  z-index: 1;
  right: calc(unit * 2);
  bottom: calc(unit * 2);

  user-select: none;
}

.buttons {
  display: flex;
  flex-direction: column;

  border-radius: var(--ring-border-radius);
}

.button {
  width: 100%;

  &.start.start {
    border-radius: var(--ring-border-radius) var(--ring-border-radius) 0 0;
  }

  &.end.end {
    margin: -1px 0 0;

    border-radius: 0 0 var(--ring-border-radius) var(--ring-border-radius);
  }
}

.tooltip {
  padding: 1px unit 2px;

  pointer-events: none;

  color: var(--ring-text-color);
}

.pipelineTarget {
  padding: unit;

  border-radius: calc(unit * 1.5);
  background: var(--ring-content-background-color);

  box-shadow:
    0 2px 6px 0 var(--ring-popup-shadow-color),
    0 4px 24px 0 var(--ring-popup-shadow-color);

  & .button {
    padding: 0 calc(unit / 2 + 2px);
  }
}
