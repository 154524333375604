.progress {
  position: absolute;

  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;

  width: 100%;

  & > .progressBar {
    box-sizing: border-box;

    height: 100%;

    background-clip: padding-box;
  }
}

.progressSuccess {
  background-color: rgba(var(--ring-main-components), 0.08);
}

.progressFailure {
  background-color: rgba(var(--ring-icon-error-components), 0.12);
}
