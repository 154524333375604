@value unit: var(--ring-unit);

.overlay {
  cursor: wait;

  opacity: 0.3;

  & > * {
    pointer-events: none;
  }
}

.grid {
  --comment-column: [start comment-start] auto;
  --author-date-column: [author-date-start] minmax(auto, max-content);
  --revision-column: [revision-start] max-content;
  --files-column: [files-start] max-content;
  --actions-column: [actions-start] max-content;

  position: relative;

  align-items: start;
  grid-column-gap: calc(unit * 2);
  grid-template-columns: var(--comment-column) var(--author-date-column) var(--files-column) [end];

  &.showActions {
    grid-template-columns:
      var(--comment-column) var(--author-date-column) var(--files-column) var(--actions-column)
      [end];
  }

  &.showRevision {
    grid-template-columns:
      var(--comment-column) var(--author-date-column) var(--revision-column) var(--files-column)
      [end];
  }

  &.showActions.showRevision {
    grid-template-columns:
      var(--comment-column) var(--author-date-column) var(--revision-column) var(--files-column)
      var(--actions-column) [end];
  }
}

@media (width <= 1200px) {
  .grid {
    grid-template-columns: var(--comment-column) var(--author-date-column) var(--files-column) [end];

    &.showActions {
      grid-template-columns:
        var(--comment-column) var(--author-date-column) var(--files-column) var(--actions-column)
        [end];
    }
  }
}

.preview {
  & .grid {
    grid-template-columns: var(--comment-column) var(--author-date-column) var(--files-column) [end];

    &.showActions {
      grid-template-columns:
        var(--comment-column) var(--author-date-column) var(--files-column) var(--actions-column)
        [end];
    }
  }
}

.overall {
  & .grid {
    --chart-column: [start chart-start] minmax(auto, max-content);
    --comment-column: [comment-start] auto;

    grid-template-columns:
      var(--chart-column) var(--comment-column) var(--author-date-column) var(--files-column)
      [end];

    &.showActions {
      grid-template-columns:
        var(--chart-column) var(--comment-column) var(--author-date-column) var(--files-column)
        var(--actions-column) [end];
    }

    &.showRevision {
      grid-template-columns:
        var(--chart-column) var(--comment-column) var(--author-date-column) var(--revision-column)
        var(--files-column) [end];
    }

    /* stylelint-disable-next-line selector-max-specificity */
    &.showActions.showRevision {
      grid-template-columns:
        var(--chart-column) var(--comment-column) var(--author-date-column) var(--revision-column)
        var(--files-column) var(--actions-column) [end];
    }
  }
}

.empty,
.loading {
  padding: unit 0;
}

.changesWrapper {
  display: flex;
  flex-direction: row;
}

.graph {
  padding-right: unit;
}

.changes {
  flex: 1;

  height: 100%;
}
