@value ellipsis from '../../../../../../ring-globals.css';

.link {
  composes: ellipsis;

  max-width: 250px;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.rightIcon {
  margin-left: 5px;

  color: var(--ring-icon-color);
}
