@value unit: var(--ring-unit);

.sidebar {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.searchWrapper {
  padding-left: calc(unit * 4);
}

.tree {
  height: 100%;
  margin-top: calc(unit * 1.5);
}

.title {
  display: flex;
  justify-content: space-between;

  padding: calc(unit * 1.5) calc(unit * 1.5) 0 calc(unit * 4);

  letter-spacing: 1px;

  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lower);
}

.icon {
  display: flex;
  align-items: center;

  height: var(--ring-line-height-lower);
}
