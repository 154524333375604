@value unit: var(--ring-unit);

.mainPanel {
  position: relative;

  display: flex;
  flex-direction: column;
}

.centered {
  max-width: calc(unit * 110.5);
  margin: auto;
}

.content {
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  padding: calc(unit * 2) calc(unit * 4) 0 calc(unit * 4);
}

.router {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}
