@value unit: var(--ring-unit);

.grid {
  display: grid;

  max-width: calc(unit * 90);
  grid-template-columns: 1fr 2fr;
}

.row {
  display: contents;

  & > span {
    padding-top: calc(unit * 0.75);
    padding-bottom: calc(unit * 0.75);

    border-bottom: 1px solid var(--ring-line-color);
  }

  & > span:first-child {
    padding-left: calc(unit * 2.5);
  }

  & > span:last-child {
    padding-right: calc(unit * 2.5);
  }
}

.inactive .row {
  color: var(--ring-disabled-color);
}

.header {
  margin-bottom: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);

  & > span:first-child {
    padding-left: calc(unit * 2.5);
  }
}

.table {
  overflow: hidden;

  border: 1px solid var(--ring-line-color);
  border-radius: unit;

  & .row:last-child {
    /* stylelint-disable-next-line selector-max-specificity */
    & span {
      border-bottom: none;
    }
  }
}

.heading {
  padding: calc(unit * 0.75) calc(unit * 2.5);

  letter-spacing: 1px;

  text-transform: uppercase;

  color: var(--ring-secondary-color);

  border-bottom: 1px solid var(--ring-line-color);

  background-color: var(--ring-sidebar-background-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);

  grid-column-start: span 2;
}

.withVersions {
  grid-template-columns: repeat(3, 1fr);

  & .heading {
    grid-column-start: span 3;
  }
}

.icon {
  margin-right: 6px;

  color: var(--ring-icon-warning-color);
}
