@value unit: var(--ring-unit);

.label {
  margin-bottom: unit;

  color: var(--ring-secondary-color);
}

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-color);
}

.time {
  display: flex;
  align-items: center;
}

.container {
  display: flex;

  min-width: max-content;

  column-gap: unit;
}

.queueInfoTitle {
  & > span {
    margin-right: calc(unit / 2);
  }
}
