@value unit: var(--ring-unit);

.selectPopup {
  --ring-font-size: 14px;
}

.selectedIcon {
  position: absolute;

  z-index: 1;

  fill: currentColor;

  top: unit;
  left: unit;
}

.selectButton {
  padding-left: calc(unit * 4);
}

.selectItem {
  padding-left: unit !important;
}

.selectItem svg {
  width: 14px;
  height: 14px;
}

.selectItem div:first-child span {
  width: 13px;
}

.selectContainer {
  margin-top: calc(unit / 2 * -1);
  margin-right: calc(unit * 3);
}

.info {
  position: absolute;
  top: 6px;
  right: 0;
}

.hardwareDocLink {
  display: block;

  margin-top: calc(unit / 2);
}
