@value unit: var(--ring-unit);

.controls {
  display: flex;

  column-gap: unit;
  row-gap: unit;
}

.searchContainer {
  flex: 1;

  max-width: 740px;
}

.search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
