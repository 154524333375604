@value unit: var(--ring-unit);

.container {
  position: relative;

  margin-bottom: calc(unit * 1.5);
}

.description {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.toggle {
  margin-bottom: calc(unit / 2 * 1.5);
}

.modeToggle {
  display: flex;

  margin-bottom: calc(unit * 1.5);
}

.modeToggleButton {
  flex: 1;
}
