@value unit: var(--ring-unit);

.actions {
  display: flex;

  width: 100%;
  column-gap: unit;

  margin-bottom: unit;
}

.remove {
  margin-right: calc(-2 * unit);
  margin-left: auto;

  color: var(--ring-secondary-color);
}

.download,
.download:not(:hover) .icon {
  color: var(--ring-main-color);
}
