@import 'yet-another-react-lightbox/styles.css';
@import 'yet-another-react-lightbox/plugins/captions.css';

@value resetButton, font-smaller from '../../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.mediaItems {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(unit * 18));
  grid-gap: 8px;

  align-items: start;

  margin: -2px;
  margin-bottom: calc(unit * 2 - 2px);
}

.toggleMediaButton {
  composes: font-smaller;

  height: calc(unit * 14);

  padding: 0 unit;

  color: var(--ring-secondary-color);
}

.lightbox {
  /* stylelint-disable-next-line */
  --yarl__color_backdrop: rgba(0, 0, 0, 0.8);
}

.playButton {
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;

  border: solid 1px var(--ring-secondary-color);
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);

  & > span {
    margin-right: -2px;
  }
}

.mediaButton {
  composes: resetButton;

  width: calc(unit * 18 + 4px);
  padding: 2px;

  cursor: pointer;

  color: var(--ring-secondary-color);

  &:focus {
    border-radius: var(--ring-border-radius);
    outline: none;
    box-shadow: inset 0 0 0 2px var(--ring-border-hover-color);
  }

  &:hover {
    color: var(--ring-link-hover-color);
    box-shadow: none;
  }

  &:hover .mediaImage {
    border: solid 1px var(--ring-link-hover-color);
    box-shadow: 0 2px 8px 0 rgba(0, 42, 76, 0.1);
  }

  &:hover .playButton {
    border: solid 1px var(--ring-link-hover-color);
    background: rgba(255, 255, 255, 1);
  }
}

.media {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  width: calc(unit * 18);
  height: calc(unit * 12);

  border: solid 1px var(--ring-line-color);
  border-radius: calc((unit - 2px) / 2);

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.videoPreview {
  position: absolute;
  z-index: 0;

  width: 100%;
  height: 100%;
}

.fileName {
  composes: font-smaller;

  z-index: 1;

  margin-top: calc(unit / 2);

  line-height: 14px;
  overflow-wrap: break-word;
}
