@value verticalCompensation: 2px;
@value unit: var(--ring-unit);

.downloadIcon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-color);
}

.downloadLink {
  z-index: var(--ring-overlay-z-index);
}
