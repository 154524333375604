@value unit: var(--ring-unit);

.icon {
  margin-right: calc(unit / 2 + 2px);

  color: var(--ring-icon-success-color);
}

.warning {
  color: var(--ring-icon-warning-color);
}

.running {
  color: var(--ring-main-color);
}

.error {
  color: var(--ring-icon-error-color);
}
