@value unit: var(--ring-unit);

.hint {
  display: block;

  margin: 0;
  padding: 0;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.textarea {
  width: 100%;

  resize: none;
}

.textarea.textarea {
  margin-top: calc(unit / 2);
}
