@value unit: var(--ring-unit);

.statusColumn,
.testNameColumn,
.failuresColumn,
.assignedColumn,
.actionsColumn {
  z-index: 2;

  padding: unit 0;
}

.statusColumn {
  display: flex;

  white-space: nowrap;
  grid-column-start: status-start;
  grid-row-start: var(--row);
}

.testNameColumn {
  overflow: hidden;
  align-items: baseline;
  grid-column-start: test-name-start;
  grid-row-start: var(--row);

  min-height: 38px;
}

.failuresColumn {
  grid-column-start: failures-start;
  grid-row-start: var(--row);
}

.assignedColumn {
  grid-column-start: assigned-start;
  grid-row-start: var(--row);
}

.actionsColumn {
  grid-column-start: actions-start;
  grid-row-start: var(--row);
}

.outerContainer {
  position: relative;

  overflow: hidden;

  margin: 0 calc(unit * -4);
}

.innerContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  padding: 0 calc(unit * 4);
}
