@value unit: var(--ring-unit);

.tag {
  height: auto;
  padding: calc(unit / 4) calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.popup {
  max-width: calc(unit * 60);
  padding: unit;

  overflow-wrap: break-word;

  font-size: var(--ring-font-size-smaller);

  & .repositories {
    display: flex;
    flex-direction: column;
    row-gap: calc(unit / 2);
  }
}
