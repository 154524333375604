@value unit: var(--ring-unit);

.container {
  min-width: calc(unit * 11.5);
}

.repository {
  display: flex;

  align-items: center;

  margin-top: -1px;

  transition: opacity var(--ring-ease);

  column-gap: calc(unit / 2);
}

.tooltipWrapper {
  display: block;
  overflow: hidden;

  white-space: nowrap;
}

.icon {
  width: calc(unit * 2);
  margin-right: calc(unit / 2);
  margin-left: 1px;

  vertical-align: -1px;
}

.name {
  max-width: calc(100% - 20px);
}

.loading {
  cursor: wait;

  opacity: 0.7;

  & > * {
    pointer-events: none;
  }
}
