@value ellipsis from '../../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.link:hover {
  text-decoration: none;

  color: var(--ring-text-color);
}

.linkContent {
  display: flex;
  overflow: hidden;

  align-items: baseline;

  height: calc(3 * unit);
}

.name {
  composes: ellipsis;

  flex: 1 1 auto;
}

.counter {
  white-space: nowrap;
}

.counterIcon {
  margin-right: 2px;
  margin-left: 6px;

  color: var(--ring-icon-secondary-color);
}

.warningIcon {
  width: calc(unit * 3);
  margin-left: calc(unit / 2);

  color: var(--ring-icon-error-color);
}

.counterText {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: normal;
}

.pendingCounter {
  color: var(--ring-main-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: normal;
}

.withOwnPending {
  font-weight: bold;
}
