@value unit: var(--ring-unit);

.container {
  box-sizing: border-box;

  min-width: 0;
  max-width: max-content;

  min-height: calc(unit * 8 + unit / 2);
  padding: calc(unit * 1.5) calc(unit * 2);

  border-radius: calc(unit * 1.5);

  background-color: var(--ring-sidebar-background-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.label {
  margin-bottom: unit;

  color: var(--ring-secondary-color);
}
