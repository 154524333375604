@value titles from '@jetbrains/ring-ui/components/tabs/tabs.css';
@value unit: var(--ring-unit);
@value popupMinHeight: calc(unit * 48);

.wrapper {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  height: 100%;
}

.tabs {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  height: 100%;
}

.tabs .titles {
  width: 100%;
  margin-bottom: 0;
}

.tabContainer {
  overflow: hidden;
  flex: 1;
}

.tabContainer .tab {
  display: flex;
  overflow: auto;
  flex-direction: column;

  box-sizing: border-box;

  height: 100%;
}

.logTab {
  overflow: hidden;
  flex: 1;

  background: var(--ring-sidebar-background-color);
}

.jobDetailsPopupTarget {
  & .header {
    padding: calc(unit * 2.5) calc(unit * 4) 0 calc(unit * 4);
  }

  & .tabs .titles {
    padding: 0 calc(unit * 4);
  }

  & .tabContainer .tab {
    padding: calc(unit * 2) calc(unit * 4);
  }

  & .logTab {
    margin: calc(unit * -1) calc(unit * -4) calc(unit * -2) calc(unit * -4);
  }
}

.jobDetailsSidebarTarget {
  & .header {
    padding: calc(unit * 2.5) calc(unit * 4) 0 calc(unit * 2.5);
  }

  & .tabs .titles {
    padding: 0 calc(unit * 4) 0 calc(unit * 2.5);
  }

  & .tabContainer .tab {
    padding: calc(unit * 2) calc(unit * 4) calc(unit * 2) calc(unit * 2.5);
  }

  & .logTab {
    margin: 0 calc(unit * -4) calc(unit * -2) calc(unit * -2.5);
  }
}
