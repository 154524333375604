@value unit: var(--ring-unit);

.message {
  display: flex;
  column-gap: unit;
}

.button {
  white-space: nowrap;
}
