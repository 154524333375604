@value unit: var(--ring-unit);

.wrapper {
  composes: animation from './Sidebar/Sidebar.css';

  position: relative;

  display: flex;

  overflow: hidden;
  flex-direction: column;

  box-sizing: border-box;
  height: 100%;

  background-color: var(--ring-sidebar-background-color);
}
