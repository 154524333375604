@value titles from '@jetbrains/ring-ui/components/tabs/tabs.css';
@value unit: var(--ring-unit);

.tabs > .titles {
  margin: 0;

  box-shadow: unset;
}

.tabContent {
  margin: 0 calc(unit * -4);

  border-top: 1px solid var(--ring-line-color);
}
