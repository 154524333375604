@value unit: var(--ring-unit);

.redirectTitle.redirectTitle {
  margin-top: calc(unit / 2);
  margin-bottom: 0;

  font-size: var(--ring-font-size);
}

.description {
  max-width: calc(unit * 65);

  margin-bottom: calc(unit / 2);
  margin-left: calc(unit * 3);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.redirectMessage.redirectMessage {
  width: calc(unit * 64.5);
  margin: calc(unit * 1.5) 0;
  margin-left: calc(unit * 3);
  padding: unit calc(unit * 2);

  font-size: var(--ring-font-size-smaller);
}

.portConfig {
  margin-top: calc(unit * 1.5);
}

.updateWarning {
  margin-top: unit;
  margin-bottom: unit;
}

.urlsInfo {
  margin-top: unit;
}

.save {
  margin-top: calc(unit * 1.5);
}
