@value unit: var(--ring-unit);

.content {
  display: flex;
  column-gap: unit;
}

.button {
  padding: 0;

  color: var(--ring-main-color);

  font-size: var(--ring-font-size);
}

.button:not(:hover) .icon {
  color: var(--ring-main-color);
}

.revoke {
  color: var(--ring-secondary-color);
}

.revokeDialog {
  width: calc(unit * 75);
}

.revokeWarning {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit);
}
