@value unit: var(--ring-unit);

.search {
  position: relative;

  & button {
    padding-right: unit;
  }
}

.wrapper {
  padding: unit calc(2 * unit) 0 30px;
}
