@value unit: var(--ring-unit);

.dialog {
  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.dialogContent {
  display: flex;
  flex: 1 0;
  flex-direction: column;

  width: calc(unit * 66);
  height: calc(100vh - unit * 8);
  max-height: calc(unit * 72);
}

.header.header {
  flex: 0 0;

  padding: calc(2 * unit) calc(4 * unit);

  font-size: calc(3 * unit);
}
