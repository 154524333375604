@value unit: var(--ring-unit);

.header {
  z-index: 3;

  display: grid;

  margin: calc(unit * -2) calc(unit * -4) 0 calc(unit * -4);
  padding: calc(unit * 2.5) calc(unit * 4) calc(unit * 2) calc(unit * 4);

  background-color: var(--ring-content-background-color);

  grid-gap: calc(unit * 1.5);
  grid-template-columns: max-content 1fr;
  grid-template-areas:
    'breadcrumbs breadcrumbs'
    'heading actions'
    'badges badges';
}

.breadcrumbs {
  grid-area: breadcrumbs;
}

.heading {
  grid-area: heading;

  overflow: hidden;
}

.actions {
  grid-area: actions;

  display: flex;
  justify-content: end;
}

.button {
  margin-left: unit;
}

.badges {
  grid-area: badges;

  display: flex;

  margin-top: calc(-0.5 * unit);
  column-gap: calc(unit / 2);

  & > div {
    max-width: calc(unit * 50);
  }
}
