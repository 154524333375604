@value unit: var(--ring-unit);

.container {
  min-width: calc(unit * 13);
}

.wrapper {
  display: block;
  overflow: hidden;

  margin-top: calc(unit / -4);

  white-space: nowrap;

  text-overflow: ellipsis;
}

.noAvatar {
  margin-top: 0;
}
