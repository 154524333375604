@value unit: var(--ring-unit);

.grid {
  --status-column: [start status-start] calc(unit * 7);
  --test-name-column: [test-name-start] minmax(200px, auto);
  --failures-column: [failures-start] minmax(116px, auto);
  --assigned-column: [assigned-start] minmax(116px, auto);
  --actions-column: [actions-start] max-content;

  position: relative;

  align-items: start;
  grid-column-gap: calc(unit * 1.5);
  grid-template-columns:
    var(--status-column) var(--test-name-column) var(--failures-column) var(--assigned-column)
    var(--actions-column) [end];
}

.list {
  display: contents;
}

.overlay {
  cursor: wait;

  opacity: 0.3;

  & > * {
    pointer-events: none;
  }
}
