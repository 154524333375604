@value unit: var(--ring-unit);

.header {
  padding: 0 calc(unit * 2) unit calc(unit * 2);

  background-color: var(--ring-content-background-color);

  &.pageTarget,
  &.jobDetailsPopupTarget {
    padding: 0 calc(unit * 4) calc(unit * 1.5) calc(unit * 4);
  }

  &.jobDetailsSidebarTarget {
    padding: 0 calc(unit * 4) calc(unit * 1.5) calc(unit * 2.5);
  }
}

.jobDetailsPopupTarget {
  & .controls {
    max-width: 1280px;
  }
}
