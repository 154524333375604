@value unit: var(--ring-unit);

.container {
  min-width: max-content;
}

.status {
  display: flex;

  margin-top: calc(-0.5 * unit);
  padding: calc(unit / 2) unit;

  color: var(--ring-white-text-color);

  border-radius: var(--ring-border-radius);
  column-gap: calc(unit / 2);

  &.running {
    background-color: var(--ring-main-color);
  }

  &.failure {
    background-color: var(--ring-icon-error-color);
  }

  &.success {
    background-color: var(--ring-icon-success-color);
  }

  &.canceled {
    background-color: var(--ring-borders-color);
  }

  &.queued {
    background-color: var(--ring-main-color);
  }
}

.icon svg {
  color: var(--ring-white-text-color);
}
