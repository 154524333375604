@value unit: var(--ring-unit);

.wrapper {
  display: inline-block;
}

.popup {
  max-height: 50vh;
}

.popupContent {
  padding: calc(unit * 2) calc(unit * 2) calc(unit * 3);

  text-align: left;
}

.hint {
  width: calc(unit * 28);
}

.titleLink {
  display: block;

  border-radius: 0;

  line-height: calc(unit * 3);
}

.text {
  margin-left: calc(unit / 4);
}

.chevron {
  margin-left: 2px;

  line-height: normal;
}
