@value withSuccessColumn, withFailureColumn, withMutedColumn, withIgnoredColumn from '../TestScopesView.css';
@value unit: var(--ring-unit);

.testScope,
.testScopeRow {
  display: contents;
}

.testScope::before {
  right: calc(unit * -4);
  left: calc(unit * -4);
  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.nameColumn {
  overflow: hidden;
  grid-column-start: name-start;
  grid-row-start: var(--row);
}

.durationColumn {
  grid-column-start: duration-start;
}

.countColumn {
  grid-column-start: count-start;
}

.successColumn {
  grid-column-start: success-start;
}

.failureColumn {
  grid-column-start: failure-start;

  color: var(--ring-error-color);
}

.mutedColumn {
  grid-column-start: muted-start;
}

.ignoredColumn {
  grid-column-start: ignored-start;
}

.durationColumn,
.countColumn,
.successColumn,
.failureColumn,
.mutedColumn,
.ignoredColumn {
  grid-row-start: var(--row);

  text-align: right;
}

.successColumn,
.failureColumn,
.mutedColumn,
.ignoredColumn {
  visibility: hidden;
}

.nameColumn,
.durationColumn,
.countColumn,
.successColumn,
.failureColumn,
.mutedColumn,
.ignoredColumn {
  margin: 0;
  padding: unit 0;
  padding-right: calc(unit * 2);

  line-height: var(--ring-line-height-lowest);

  &:last-child {
    padding-right: 0;
  }
}

.withSuccessColumn .successColumn,
.withFailureColumn .failureColumn,
.withMutedColumn .mutedColumn,
.withIgnoredColumn .ignoredColumn {
  visibility: visible;
}

.jobDetailsSidebarTarget {
  &.testScope::before {
    left: calc(unit * -2.5);
  }
}
