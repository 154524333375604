@value unit: var(--ring-unit);

.branch {
  padding-top: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.editButtom {
  padding-bottom: calc(unit / 2);

  opacity: 0;
}

.toggle {
  display: flex;

  margin-bottom: calc(unit * 1.5);

  & span:first-child {
    flex-shrink: 0;

    margin-top: 3px;
  }

  & span:nth-child(2) {
    flex: 1;
  }
}

.toggleDeleted {
  cursor: default;

  text-decoration: line-through;

  color: var(--ring-secondary-color);
}

.folderRoot {
  padding-top: 2px;
  padding-right: 2px;

  color: var(--ring-secondary-color);
}

.folderContainer {
  display: flex;

  align-items: start;

  padding-top: calc(unit / 2);

  & > * {
    transition: opacity var(--ring-ease);
  }

  &:hover .editIcon {
    color: var(--ring-link-hover-color);
  }

  &:hover .editButtom {
    opacity: 1;
  }
}

.formContainer {
  display: flex;

  align-items: start;
}

.inputFolder,
.folder {
  flex: 1;

  padding-left: 2px;
}

.folder {
  box-sizing: border-box;
  padding-top: 2px;
}
