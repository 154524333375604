@value unit: var(--ring-unit);

.form {
  display: flex;

  column-gap: unit;
  row-gap: unit;
}

.inputContainer {
  flex: 1;
}

.input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.noResults {
  align-self: center;

  &.loading {
    color: var(--ring-secondary-color);
  }
}

.progress {
  display: inline-block;

  width: calc(unit * 2);

  padding-top: 1px;
}

.reset {
  padding: 0 calc(unit / 2);
}

.previousNextButton {
  padding: 0 unit;
}
