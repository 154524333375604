@value unit: var(--ring-unit);

.report {
  margin-bottom: calc(unit * 2);
  padding: calc(unit * 2) calc(unit * 2) calc(unit * 3);

  border-radius: var(--ring-border-radius);
}

.withErrors {
  background-color: var(--ring-removed-background-color);
}

.withWarnings {
  background-color: var(--ring-warning-background-color);
}

.withInfo {
  background-color: var(--ring-sidebar-background-color);
}

.empty {
  background-color: var(--ring-added-background-color);
}

.reportHeading.reportHeading {
  margin-bottom: calc(unit * 3);
}

.reportColumns {
  display: flex;
  align-items: baseline;
}

.reportColumn {
  margin-right: calc(unit * 8);
}

.reportColumnHeading.reportColumnHeading {
  margin-bottom: calc(unit / 2);
}

.reportColumnCount {
  color: var(--ring-secondary-color);
}

.errorIcon,
.warningIcon,
.infoIcon {
  margin-left: 5px;
}

.errorIcon {
  color: var(--ring-icon-error-color);
}

.warningIcon {
  color: var(--ring-icon-warning-color);
}

.infoIcon {
  color: var(--ring-icon-secondary-color);
}

.notificationsHeaderWrapper {
  display: flex;
  align-items: baseline;

  margin-bottom: calc(unit / 2);

  font-weight: 600;
}

.notificationsHeader {
  color: var(--ring-secondary-color);
}

.markAsRead {
  margin-left: auto;

  white-space: nowrap;

  &[disabled] {
    visibility: hidden;

    width: 0;
  }
}
