@value unit: var(--ring-unit);
@value font-smaller from '../../../../../../ring-globals.css';

.container {
  min-width: calc(unit * 9);
}

.wrapper {
  display: block;

  margin-top: -1px;
}

.branch {
  padding: 0;

  background-color: transparent;
}

.branchSelectButton {
  composes: font-smaller;

  display: inline-block;

  height: calc(unit * 3);
  margin-top: calc(unit / -2);

  & > button[type='button'] {
    padding: 0;
  }

  & > button {
    max-width: 150px;

    background-color: transparent;

    &:hover {
      color: var(--ring-link-hover-color);
      background-color: transparent;
    }
  }
}

.branchName {
  overflow: hidden;
}
