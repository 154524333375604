@value unit: var(--ring-unit);

.instancesDropdown {
  line-height: var(--ring-line-height);
}

.popup {
  width: calc(50 * unit);
  margin-left: calc(-2 * unit);
  padding: unit calc(2 * unit);

  line-height: calc(4 * unit);
}

.item {
  display: flex;
  justify-content: space-between;
}

.link {
  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;
}

.runningStatus,
.idleStatus,
.disabledStatus,
.disconnectedStatus {
  margin-left: unit;
}

.runningStatus {
  white-space: nowrap;

  color: var(--ring-success-color);
}

.icon {
  margin-right: calc(0.5 * unit);
}

.idleStatus,
.disabledStatus {
  color: var(--ring-secondary-color);
}

.disconnectedStatus {
  color: var(--ring-error-color);
}

.viewAllLink {
  color: var(--ring-secondary-color);
}

.inactiveLink {
  color: var(--ring-secondary-color);
}
