@value unit: var(--ring-unit);

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-color);

  & svg {
    width: calc(unit * 1.5);
    height: calc(unit * 1.5);

    vertical-align: -2px;
  }
}
