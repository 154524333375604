@value unit: var(--ring-unit);

.section {
  margin: calc(unit * 2) 0;
}

.subtitle {
  margin-top: 0;
  margin-bottom: unit;

  font-size: var(--ring-font-size);
  font-weight: 600;
  line-height: var(--ring-line-height);
}

.text {
  margin-top: calc(unit / 4);
  margin-bottom: calc(unit / 4);
  padding: 0;
}
