@value unit: var(--ring-unit);

.input {
  margin-top: calc(unit * 1.5);
}

.confirmationText {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit / 2);
}
