@value unit: var(--ring-unit);

.toggle {
  margin-bottom: calc(unit * 2);

  padding-right: 0;
  padding-left: 0;

  color: var(--ring-main-color);

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.icon {
  width: calc(unit * 2);
  height: calc(unit * 2);
}

.toggle:not(:hover) .icon {
  color: var(--ring-main-color);
}
