.couter {
  padding-right: 0;

  padding-left: 0;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.runningCount {
  color: var(--ring-success-color);
}

.couter:hover .runningCount {
  color: inherit;
}

.helpIcon {
  margin-left: calc(var(--ring-unit) / 2);

  cursor: pointer;

  color: var(--ring-icon-color);

  &:hover {
    color: var(--ring-icon-hover-color);
  }
}
