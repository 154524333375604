@value dark from '@jetbrains/ring-ui/components/global/variables_dark.css';
@value unit: var(--ring-unit);

.header {
  display: flex;
  align-items: baseline;

  margin: calc(unit * 2) 0 calc(unit * 3.5);
}

.controls {
  margin-left: auto;
}

.counter {
  margin-left: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-weight: normal;
}

.errorIcon {
  position: relative;
  top: -2px;

  margin-right: 5px;

  color: var(--ring-icon-error-color);
}

.noNotifications {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: calc(unit * 3);
}

.noNotificationsText {
  margin-top: calc(unit * 5);
}

:root {
  --tc-bell-color: #000;
}

.dark {
  --tc-bell-color: #fff;
}
