@value unit: var(--ring-unit);
@value jobWidth: 196px;
@value jobHeight: 88px;

.job {
  --job-default-base-color: rgb(255, 255, 255);
  --job-default-selected-color: rgb(235, 246, 255);
  --job-success-base-color: rgb(232, 244, 232);
  --job-success-selected-color: rgb(216, 240, 216);
  --job-warning-base-color: rgb(248, 242, 226);
  --job-warning-selected-color: rgb(250, 236, 205);
  --job-error-base-color: rgb(251, 231, 226);
  --job-error-selected-color: rgb(255, 213, 203);
  --job-canceled-base-color: rgb(255, 255, 255);
  --job-canceled-selected-color: rgb(229, 236, 242);

  @nest :global(.ring-ui-theme-dark) & {
    --job-default-base-color: rgb(50, 52, 57);
    --job-default-selected-color: rgb(40, 51, 71);
    --job-success-base-color: rgb(46, 59, 49);
    --job-success-selected-color: rgb(51, 75, 53);
    --job-warning-base-color: rgb(68, 60, 49);
    --job-warning-selected-color: rgb(94, 77, 57);
    --job-error-base-color: rgb(74, 49, 50);
    --job-error-selected-color: rgb(95, 54, 53);
    --job-canceled-base-color: rgb(37, 38, 41);
    --job-canceled-selected-color: rgb(50, 52, 57);
  }

  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: calc(unit / 4);

  box-sizing: border-box;

  width: jobWidth;
  height: jobHeight;
  padding: unit;

  text-decoration: none;

  color: var(--ring-text-color);
  border: 2px solid var(--job-tile-border-color);

  border-radius: calc(unit * 1.5);

  outline-color: var(--ring-border-hover-color);

  background-color: var(--job-tile-background-color);

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height-lower);

  &:hover {
    text-decoration: none;
  }
}

.name {
  display: -webkit-box;
  overflow: hidden;

  text-overflow: ellipsis;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.top {
  display: flex;

  width: 100%;

  font-weight: bold;
}

.topLeft {
  display: flex;

  padding-right: calc(unit / 2);
}

.topRight {
  margin-right: calc(unit / -2);
  margin-left: auto;
}

.status {
  display: -webkit-box;
  overflow: hidden;

  margin-top: auto;

  text-overflow: ellipsis;
  word-break: break-word;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.top,
.status {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.topLeft,
.topRight,
.name,
.status {
  position: relative;
  z-index: 1;

  background-color: var(--job-tile-background-color);
}

.default,
.success,
.warning,
.error,
.canceled {
  --job-tile-border-color: transparent;
}

.default {
  --job-tile-background-color: var(--job-default-base-color);

  &.hovered,
  &.selected,
  &:hover {
    --job-tile-background-color: var(--job-default-selected-color);
  }

  &.selected {
    --job-tile-border-color: var(--ring-main-color);
  }
}

.success {
  --job-tile-background-color: var(--job-success-base-color);

  &.hovered,
  &.selected,
  &:hover {
    --job-tile-background-color: var(--job-success-selected-color);
  }

  &.selected {
    --job-tile-border-color: var(--ring-icon-success-color);
  }
}

.warning {
  --job-tile-background-color: var(--job-warning-base-color);

  &.hovered,
  &.selected,
  &:hover {
    --job-tile-background-color: var(--job-warning-selected-color);
  }

  &.selected {
    --job-tile-border-color: var(--ring-icon-warning-color);
  }

  & .status {
    color: var(--ring-warning-color);
  }
}

.error {
  --job-tile-background-color: var(--job-error-base-color);

  &.hovered,
  &.selected,
  &:hover {
    --job-tile-background-color: var(--job-error-selected-color);
  }

  &.selected {
    --job-tile-border-color: var(--ring-icon-error-color);
  }
}

.canceled {
  --job-tile-background-color: var(--job-canceled-base-color);

  &.hovered,
  &.selected,
  &:hover {
    --job-tile-background-color: var(--job-canceled-selected-color);
  }

  &.selected {
    --job-tile-border-color: var(--ring-borders-color);
  }
}

.selected {
  & .name {
    font-weight: bold;
  }
}

.deleted {
  color: var(--ring-disabled-color);

  & .name {
    text-decoration: line-through;
  }
}
