@value unit: var(--ring-unit);

.section {
  margin: calc(unit * 2) 0;
}

.controls {
  display: flex;

  margin-top: calc(unit * 1.5);
}

.submit {
  margin-right: unit;
}

.more {
  margin-right: 0;
  margin-left: auto;
  padding-right: 0;
}
