@value unit: var(--ring-unit);

.editUrl {
  margin-right: calc(unit * -1);

  transition: opacity var(--ring-ease);

  opacity: 0;
}

.urlSectionWrapper {
  padding-bottom: calc(unit * 1.5);
}

.urlSection {
  display: flex;
  align-items: baseline;

  height: 22px;

  cursor: pointer;

  &:hover .editUrl {
    opacity: 1;
  }

  &:hover .editUrlIcon {
    color: var(--ring-link-hover-color);
  }
}

.input {
  margin: -2px 0;

  background-color: var(--ring-content-background-color);
}

.branchSelectButton {
  height: auto;

  color: var(--ring-text-color);

  line-height: inherit;
}

.settingsFormContainer {
  padding-top: 0;
}

.createFormContainer {
  padding-top: unit;
}
