@value unit: var(--ring-unit);

.message {
  position: relative;

  display: inline-block;

  overflow: hidden;

  padding-left: calc(unit * 2);

  cursor: text;

  user-select: text;

  white-space: pre;
  word-break: break-all;
  line-break: anywhere;
}

.treeMessage {
  padding-left: calc(unit * 3.5);
}

.text {
  position: relative;

  display: inline-block;
}

.searchHighlighiting {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  user-select: none;

  pointer-events: none;

  color: transparent;
}

.breakLines {
  white-space: pre-wrap;
}

.message.error,
.message.error:hover {
  color: var(--ring-error-color);
}

.message.warning,
.message.warning:hover {
  color: var(--ring-warning-color);
}

.message.verbose,
.message.verbose:hover {
  color: var(--ring-secondary-color);
}

.collapseButton {
  position: absolute;
  top: -2px;
  left: 0;

  padding: 0;
  padding-right: calc(unit / 2 + 2px);
  padding-left: unit;

  user-select: none;

  font-family: var(--ring-font-family-monospace);
  font-size: var(--ring-font-size-smaller);

  & .collapseIcon {
    transition: none;

    color: var(--ring-disabled-color);
  }

  &:hover {
    & .collapseIcon {
      color: var(--ring-icon-hover-color);
    }
  }
}

.loaderWrapper {
  position: absolute;
  top: 0;
  left: unit;
}

.loader {
  display: inline-block;
}

.duration {
  user-select: none;

  color: var(--ring-secondary-color);
}

.largeStepHeading {
  & .duration {
    font-weight: normal;
  }

  & .collapseButton {
    top: -3px;
  }
}

.largeStepHeading,
.largeStepHeading:hover {
  color: var(--ring-text-color) !important;
}
