@value resetButton from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.avatars {
  position: relative;
  z-index: 2;

  display: inline-flex;

  margin-right: calc(unit / 2);
}

.avatarWrapper {
  margin-right: 0;

  &::before {
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;

    content: '';
  }
}

.item {
  /* stylelint-disable-next-line length-zero-no-unit */
  --offset: 0px;

  position: relative;

  margin-left: calc(var(--offset) * -1);

  transition: transform var(--ring-fast-ease);
  mask-image: url('ChangesAvatarsMask.svg');
  mask-position: calc(var(--offset) - 4px) -2px;
  mask-repeat: no-repeat;
}

.item:nth-child(1) {
  z-index: 4;
  mask: none;
}

.item:nth-child(2) {
  --offset: 10px;

  z-index: 3;
}

.item:nth-child(3) {
  --offset: 14px;

  z-index: 2;
}

.item:nth-child(4) {
  --offset: 15px;

  z-index: 1;
}

.hovered > .item,
.avatars:hover > .item,
.avatars:focus-within > .item {
  mask: none;
}

/* stylelint-disable selector-max-specificity, no-descending-specificity */
.hovered > .item:nth-child(2),
.avatars:hover > .item:nth-child(2),
.avatars:focus-within > .item:nth-child(2) {
  transform: translateX(12px);
}

.hovered > .item:nth-child(3),
.avatars:hover > .item:nth-child(3),
.avatars:focus-within > .item:nth-child(3) {
  transform: translateX(28px);
}

.hovered > .item:nth-child(4),
.avatars:hover > .item:nth-child(4),
.avatars:focus-within > .item:nth-child(4) {
  transform: translateX(45px);
}
/* stylelint-enable */

.extra {
  composes: item;

  width: 20px;
  height: 20px;

  color: var(--ring-text-color);

  border-radius: var(--ring-border-radius);

  background-color: var(--ring-line-color);

  font-size: 9px;

  line-height: 20px;
}

.extraText {
  composes: resetButton;

  display: block;

  width: 100%;

  cursor: pointer;
  transition: opacity var(--ring-fast-ease);
  text-align: center;

  opacity: 0;

  border-radius: var(--ring-border-radius);
}

.extraText:global(.focusVisible) {
  outline: none;
  box-shadow: 0 0 0 2px var(--ring-border-hover-color);
}

.hovered .extraText,
.hovered .othersAvatar,
.avatars:hover .extraText,
.avatars:hover .othersAvatar,
.avatars:focus-within .extraText,
.avatars:focus-within .othersAvatar {
  opacity: 1;
}

.avatar {
  position: relative;
}

.listAvatarWrapper {
  vertical-align: -5px;
}

.listAvatar {
  vertical-align: baseline;
}

.link {
  display: block;
}

/* stylelint-disable-next-line selector-max-specificity */
.link:global(.focusVisible) .avatarWrapper::before {
  right: 0;
}
