@value copyrightWidth: calc(32 * unit);
@value sideColumnWidth: calc(100% / 2 - copyrightWidth / 2);
@value unit: var(--ring-unit);

.footerPanel {
  position: fixed;
  z-index: 5;

  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  overflow: hidden;

  border-top: 1px solid var(--ring-line-color);
  background-color: var(--ring-content-background-color);
}

.pluginWrapper {
  width: 100%;
  height: 100%;
}

.resizable {
  display: flex;
  flex-direction: column;
}

.resizableTopHandle {
  z-index: 20;
}
