@value unit: var(--ring-unit);

.fullBuildLog {
  position: relative;

  display: flex;
  flex-direction: column;

  color: var(--ring-text-color);

  background-color: var(--ring-content-background-color);
}

.messages {
  flex-grow: 1;
}

.messagesWrapper {
  display: flex;
  flex-grow: 1;
}
