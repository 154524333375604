@value remove from '@jetbrains/ring-ui/components/tag/tag.css';
@value unit: var(--ring-unit);

.tag {
  vertical-align: baseline;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tag + .remove {
  top: 2px;
}

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-color);
}

.multiple {
  position: relative;
  z-index: 1;

  display: inline-flex;
}

.selected {
  &,
  &::before {
    transition: background-color var(--ring-ease);

    background-color: var(--ring-main-color);
  }

  &,
  &:hover {
    color: var(--ring-white-text-color);
  }

  &:hover,
  &:focus {
    &,
    &::before {
      transition: none;

      background-color: var(--ring-main-hover-color);

      box-shadow: none;
    }
  }
}

.link {
  max-width: 100%;
}
