@value unit: var(--ring-unit);
@value font-smaller-lower from '../../../../../ring-globals.css';

.dialog {
  width: calc(unit * 66);
  height: calc(100vh - unit * 8);
  max-height: calc(unit * 72);
}

.wrapper {
  display: flex;
  flex: 1 1 0;
}

.search {
  width: auto;
  margin-right: calc(unit * 4);
}

.content {
  flex-grow: 1;

  height: 100%;
}

.scrollableWrapper {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 0;
}

.header {
  padding: calc(unit * 4) calc(unit * 4) 0;
}

.shortcuts {
  composes: font-smaller-lower;

  margin: unit calc(unit * 4) calc(unit * 2);

  color: var(--ring-secondary-color);

  font-weight: normal;
}

.footer {
  display: flex;
  align-items: baseline;

  box-shadow: inset 0 1px var(--ring-line-color);

  line-height: calc(unit * 3);
}

.nothingFound {
  composes: font-smaller-lower;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  flex-grow: 1;

  margin: calc(unit * 2) 0 0 calc(unit * 4);

  color: var(--ring-secondary-color);
}

.tree {
  flex: 1 1 auto;
}
