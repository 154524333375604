@value unit: var(--ring-unit);

.tags {
  display: flex;
  flex-wrap: wrap;

  margin-top: calc(var(--ring-unit) / 2);
}

.tag {
  margin-right: calc(unit / 2);
  margin-bottom: calc(unit / 2);

  &.active {
    background: var(--ring-hover-background-color);
    box-shadow: inset 0 0 0 1px var(--ring-main-color);
  }
}
