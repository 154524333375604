@value font-smaller-lower from '../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.legend {
  composes: font-smaller-lower;

  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: center;

  margin: calc(unit * 4) calc(unit * -0.5) unit;
  padding: 0;

  list-style-type: none;

  & > li {
    padding: 0 calc(unit * 0.5);
  }
}

.marker {
  --size: calc(unit * 1.5);

  display: inline-block;

  box-sizing: border-box;

  width: var(--size);
  height: var(--size);

  margin-right: calc(unit * 0.5);

  vertical-align: -1px;

  border-radius: 50%;
}

.queue {
  composes: marker;

  box-shadow: inset 0 0 0 2px var(--tc-success-progress-color);
}

.success {
  composes: marker;

  background-color: var(--tc-success-progress-color);
}

.failed {
  composes: marker;

  background-color: var(--ring-error-color);
}

.canceled {
  composes: marker;

  background-color: var(--ring-line-color);
}

.running {
  composes: marker;

  background-color: var(--tc-success-background-color);
  background-image: linear-gradient(
    135deg,
    var(--ring-icon-success-color),
    var(--ring-icon-success-color) 3.75%,
    transparent 3.75%,
    transparent 46.25%,
    var(--ring-icon-success-color) 46.25%,
    var(--ring-icon-success-color) 53.75%,
    transparent 53.75%,
    transparent 96.25%,
    var(--ring-icon-success-color) 96.25%,
    var(--ring-icon-success-color)
  );
  background-size: unit unit;
}

.overdue {
  composes: marker;

  background-color: var(--ring-sidebar-background-color);
  background-image: linear-gradient(
    135deg,
    var(--ring-line-color),
    var(--ring-line-color) 3.75%,
    transparent 3.75%,
    transparent 46.25%,
    var(--ring-line-color) 46.25%,
    var(--ring-line-color) 53.75%,
    transparent 53.75%,
    transparent 96.25%,
    var(--ring-line-color) 96.25%,
    var(--ring-line-color)
  );
  background-size: unit unit;
}

.queued {
  composes: marker;

  border: 1px dashed var(--ring-borders-color);
}

.toStart {
  color: var(--ring-error-color);
}

.other {
  color: var(--ring-warning-color);
}

.reasons {
  margin-left: unit;
}
