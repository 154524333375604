@value unit: var(--ring-unit);

.animation {
  transition: all 0.25s;
}

.sidebar {
  composes: animation;

  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;

  overflow: hidden;

  box-sizing: border-box;

  height: 100%;

  border-left: 1px solid var(--ring-line-color);
  background-color: var(--ring-content-background-color);
}
