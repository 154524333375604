@value unit: var(--ring-unit);

.grid {
  --status-column: [start status-start] max-content;
  --build-column: [start build-start] max-content;
  --problem-name-column: [problem-name-start] auto;
  --changes-column: [changes-start] minmax(auto, 200px);
  --assigned-column: [assigned-start] minmax(auto, 200px);
  --actions-column: [actions-start] minmax(auto, 200px);

  position: relative;

  align-items: start;
  grid-column-gap: calc(unit * 1.5);
  grid-template-columns:
    var(--status-column) var(--build-column) var(--problem-name-column) var(--changes-column)
    var(--assigned-column) var(--actions-column) [end];
}

.list {
  display: contents;
}

.nothingFound {
  padding: unit 0;

  color: var(--ring-secondary-color);
}
