@value resetButton from '../../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.tableHeader {
  display: contents;

  line-height: var(--ring-line-height-lowest);

  &::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: none;

    border-bottom: 1px solid var(--ring-line-color);

    grid-column: start / end;
    grid-row: var(--row) / span 1;
  }
}

.columnTitle {
  composes: resetButton;

  cursor: pointer;
}

.statusColumn {
  grid-column-start: status-start;

  display: flex;

  white-space: nowrap;
}

.nameColumn {
  grid-column-start: name-start;
}

.durationColumn {
  grid-column-start: duration-start;

  text-align: right;
}

.runOrderColumn {
  grid-column-start: run-order-start;

  text-align: right;
}

.actionsColumn {
  grid-column-start: actions-start;
}

.statusColumn,
.nameColumn,
.durationColumn,
.runOrderColumn,
.actionsColumn {
  box-sizing: border-box;
  padding: unit 0;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  grid-row-start: var(--row);
}

.jobDetailsPopupTarget::before {
  right: calc(unit * -4);
  left: calc(unit * -4);
}

.jobDetailsSidebarTarget::before {
  right: calc(unit * -4);
  left: calc(unit * -2.5);
}

.sortIcon {
  margin-left: calc(unit / 4);

  vertical-align: middle;
}
