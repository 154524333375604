@value unit: var(--ring-unit);

.main {
  overflow: auto;

  flex: 1;

  padding: calc(unit * 2.5) calc(unit * 4) calc(unit * 2) calc(unit * 4);
}

.openSidebar {
  & .main {
    padding-right: calc(unit * 2.5);
  }
}
