@value unit: var(--ring-unit);

.page {
  height: 100%;
  min-height: calc(unit * 50);
  margin: 0 calc(unit * -4) 0;
}

.jobDetailsSidebarTarget {
  overflow: hidden;
  flex: 1;

  margin-bottom: calc(unit * -2);
  margin-left: calc(unit * -2.5);
}

.log {
  height: 100%;
}
