@value innerContainer from '@jetbrains/ring-ui/components/dialog/dialog.css';
@value unit: var(--ring-unit);

.content {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.log {
  flex-grow: 1;
}

.dialog {
  width: calc(100% - unit * 4 * 2);
  height: calc(100% - unit * 2.5 * 2);
}

.overlay {
  padding: 0;

  & .innerContainer {
    width: 100%;
    height: 100%;
  }
}
