.iconAction {
  display: inline-flex;

  padding: 0 3px;

  &:disabled {
    visibility: hidden;
  }
}

.starIcon {
  &.starIcon {
    transition: none;
  }

  &.hovered {
    color: var(--ring-icon-hover-color);
  }
}

.tail {
  display: flex;
}

.counter {
  margin-right: var(--ring-unit);
}
