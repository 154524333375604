@value unit: var(--ring-unit);

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  max-width: var(--tc-content-width);
}

.content {
  margin-top: calc(4 * unit);
}
