@value unit: var(--ring-unit);

.anchor {
  height: auto;

  margin-left: unit;

  cursor: pointer;

  color: var(--ring-icon-color);

  line-height: inherit;

  &:hover {
    color: var(--ring-link-hover-color);
  }
}

.detailsPopup {
  max-width: calc(unit * 36);
  padding: unit calc(unit * 1.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
