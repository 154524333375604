@value unit: var(--ring-unit);

.tooltip {
  max-width: calc(unit * 60);
  padding: unit;

  overflow-wrap: break-word;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
