@value unit: var(--ring-unit);

.licenses {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 2);
}

.warning {
  display: flex;
  column-gap: unit;

  align-items: flex-start;
}

.link {
  composes: link from '../LicenseInformation.css';
}
