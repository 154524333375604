.container {
  display: flex;
  align-items: center;
}

.copyButton {
  height: var(--ring-line-height);

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height);
}

.jobId {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
