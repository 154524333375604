@value unit: var(--ring-unit);

.header {
  min-height: calc(28px + var(--ring-line-height-lowest));
  padding: calc(unit / 2) calc(unit * 2) calc(unit * 2);
}

.title.title {
  margin: 0;
  padding: 0;

  font-size: 24px;

  font-weight: bold;
  line-height: 28px;
}

.link {
  color: inherit;

  &:hover {
    color: var(--ring-link-hover-color);
  }
}

.title.failure {
  color: var(--ring-error-color);
}

.title.success {
  color: var(--ring-success-color);
}

.statusIcon {
  margin-right: calc(unit / 2);
}

.description {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.descriptionText {
  line-height: var(--ring-line-height-lowest);
}
