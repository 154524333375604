@value font-smaller from '../../../../../../ring-globals.css';
@value captionWidth: calc(unit * 8);
@value unit: var(--ring-unit);

.dialog {
  width: calc(unit * 65);
}

.container {
  display: flex;
  flex-direction: column;
}

/* FORM */

.form {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;

  min-height: 0;
}

.fields {
  padding-bottom: calc(unit * 4);
}

.portalTarget {
  position: fixed;
  z-index: var(--ring-overlay-z-index);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;

  & > * {
    pointer-events: initial;
  }
}

.result {
  font-size: var(--ring-font-size, 14px);
  line-height: 13px;
}

.rowGroup {
  margin: calc(unit * 1.5) 0 0 calc(2 * unit);
}

.separateRow {
  margin-top: calc(2.5 * unit);
}

.row {
  display: flex;

  align-items: baseline;
  flex-wrap: nowrap;
}

.checkboxItem {
  display: flex;
}

.checkboxesContainer {
  display: flex;
  flex-direction: column;
}

.nested {
  padding-left: calc(unit * 3 - 2px);
}

/* PRESERVE SECTION */
.checkboxContainer {
  flex-direction: column;
}

.checkbox {
  padding-bottom: calc(unit / 2);
}

.preserveArtifactsInput {
  flex-basis: 100%;

  margin: calc(unit / 2) 0;
}

.preserveArtifactsToggle {
  height: inherit;

  line-height: inherit;
}

.artifactsWrapper {
  flex-direction: column;
}

.artifactsPatternInput {
  padding: calc(unit / 2) 0 unit;
}

.caption {
  flex-shrink: 0;

  width: captionWidth;

  margin-right: calc(unit * 3);
}

.hint {
  composes: font-smaller;

  margin-top: calc(0.5 * unit);

  color: var(--ring-secondary-color);
}

.headerHint {
  margin-top: 0;
  margin-left: calc(4 * unit);
}

.content {
  margin-top: calc(0.5 * unit);
}

.input {
  box-sizing: border-box;
  width: 100%;

  height: calc(3 * unit);
  margin: 0;
  padding-right: calc(unit / 2);
  padding-bottom: 3px;
  padding-left: calc(unit / 2);

  border: 1px solid var(--ring-borders-color);

  border-radius: 0;
  background-color: var(--ring-content-background-color);

  line-height: 20px;
  appearance: textfield;

  &_correct {
    border-color: var(--ring-success-color);
  }

  &_error {
    border-color: var(--ring-error-color);
  }

  &_no-resize {
    resize: none;
  }

  &_filter-popup {
    width: 100%;
    min-width: 200px;
  }

  &.short {
    width: 60px;
  }

  &.forSection {
    margin-right: unit;

    text-align: right;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}

.inputs {
  display: flex;
}

.switch {
  display: inline-flex;
}

.column {
  flex-direction: column;
}

.description {
  padding-top: unit;
}

.textarea {
  width: 100%;
  height: auto;
  min-height: calc(unit * 8);

  resize: none;

  &[disabled] {
    color: var(--ring-disabled-color);
  }

  &:focus {
    border-color: var(--ring-main-color);
    outline: none;
  }
}

.radioItem {
  height: calc(unit * 3);
  margin-top: 0;
}

.addCriteriaButton {
  margin-left: calc(captionWidth + unit * 2);
}

.popup {
  width: calc(unit * 20);
  padding: calc(unit * 2);
}

.error {
  height: 1em;

  padding-top: unit;

  color: var(--ring-error-color);

  line-height: 1;
}

.ownershipDescription {
  max-width: calc(unit * 80);
  margin: unit 0;
}

.textButton {
  padding-left: 0;
}

.branchExclude {
  margin-left: calc(unit * 2);
}

.panel {
  display: flex;
  align-items: baseline;
}

.title.title {
  font-size: inherit;
  line-height: inherit;
}
