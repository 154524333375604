@value unit: var(--ring-unit);
@value ellipsis from '../../../../../../../ring-globals.css';

.container {
  display: flex;

  width: 100%;
}

.duration {
  display: flex;
  align-items: start;
  flex-direction: column;

  width: 100%;
}

.wrapper {
  position: relative;

  width: 100%;

  height: calc(unit / 2);

  opacity: 1;

  border-radius: 6px;

  background-color: var(--ring-tag-background-color);
}

.bar {
  height: calc(unit / 2);

  margin-bottom: calc(unit / 2);

  animation: bar 1s linear infinite;

  border-radius: 6px;
}

@keyframes bar {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.text {
  composes: ellipsis;

  position: relative;

  text-align: left;
}

.progress {
  background-color: var(--ring-main-color);
}

.textProgress {
  color: var(--ring-main-color);

  font-weight: 600;
}

.queue {
  width: 100%;

  background-color: var(--ring-borders-color);
}

.icon {
  margin-right: unit;

  color: inherit;
}
