@value font-smaller from '../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.unsavedChanges {
  color: var(--ring-secondary-color);
}

.unsavedChanges,
.errorCounter,
.warningCounter {
  composes: font-smaller;

  margin-left: calc(unit * 1.5);
}

.errorCounter,
.warningCounter {
  display: inline-block;
}

.errorCounter,
.errorIcon {
  color: var(--ring-icon-error-color);
}

.warningCounter,
.warningIcon {
  color: var(--ring-icon-warning-color);
}

.errorIcon,
.warningIcon {
  margin-right: calc(unit / 2);
}
