@value unit: var(--ring-unit);

.breadcrumbs {
  position: sticky;

  bottom: 0;
  left: 0;

  box-sizing: border-box;

  min-height: 30px;

  padding: calc(unit / 2) calc(unit * 4);

  border-top: 1px solid var(--ring-line-color);

  background-color: var(--ring-sidebar-background-color);
}

.list {
  flex: 0 0 calc(unit * 2.5);

  margin: 0;
  padding: 0;

  list-style: none;

  color: var(--foreground-color);

  font-size: var(--ring-font-size-smaller);
}

.breadcrumb {
  display: inline;

  color: var(--ring-secondary-color);
}

.breadcrumb::after {
  margin: 0 unit;

  content: '/';

  color: var(--ring-line-color);
}

.breadcrumb:last-child::after {
  content: '';
}

.breadcrumbArray::before {
  margin-right: calc(unit / 2);

  content: '[]';
  vertical-align: 1px;

  font-size: 10px;
}

.breadcrumbObject::before {
  margin-right: calc(unit / 2);

  content: '{}';
  vertical-align: 1px;

  font-size: 10px;
}
