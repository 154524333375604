@value unit: var(--ring-unit);
@value section from '../SettingsBlock.css';

.collapsibleContainer {
  margin-bottom: 0;
}

.header {
  position: relative;

  display: flex;
  align-items: center;
  column-gap: unit;

  line-height: 24px;
}

h2.title {
  display: flex;
  justify-items: stretch;

  overflow: hidden;

  flex: 1;

  margin: 0;
  padding: 0;

  font-size: var(--ring-font-size);
  font-weight: 600;
}

.titleUnion {
  cursor: pointer;
}

.titleUnion:hover,
.titleUnion:hover .actionIcon {
  color: var(--ring-link-hover-color);
}

.buttonUnhover:hover {
  color: var(--ring-text-color);
}

.collapsible {
  padding-top: 0;
}

.deleted {
  text-decoration: line-through;

  color: var(--ring-secondary-color);
}

.toggle {
  display: block;

  width: 100%;

  height: auto;
  padding-right: 0;
  padding-left: 0;

  text-align: left;

  color: var(--ring-text-color);

  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  & > span {
    display: block;
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.toggleWithEditing {
  padding-right: 2px;
}

.toggleIcon.toggleIcon {
  color: var(--ring-icon-secondary-color);
}

.handle {
  position: absolute;
  left: -15px;

  padding: 0;

  transition: opacity var(--ring-ease);

  opacity: 0;
  color: var(--ring-icon-color);
}

.section:hover .handle,
.section:hover .actions > * {
  opacity: 1;
}

.detailsLink {
  display: inline-block;

  margin-top: 2px;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.input {
  margin-left: -4px;

  background-color: var(--ring-content-background-color);
}

.collapsedNote {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
