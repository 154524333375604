@value font from '../../../../ring-globals.css';
@value linkWrapper from '../ChangesDropdownLink/ChangesDropdownLink.css';
@value unit: var(--ring-unit);

.wrapper {
  composes: font;

  display: inline-flex;
}

.newUserInterfaceWrapper {
  display: inline-flex;
  align-items: baseline;

  min-width: 0;
  max-width: 100%;

  white-space: nowrap;
}

.dropdown {
  display: flex;
  justify-content: center;

  max-width: 100%;
  height: calc(unit * 2);
}

.label {
  overflow: hidden;
  flex-grow: 1;

  min-width: 0;
  margin: -2px;

  & .linkWrapper {
    display: flex;
    align-items: baseline;

    margin: 2px;
  }
}

.anchorIcon {
  height: unset;
  padding-right: calc(unit / 4);
  padding-left: calc(unit / 4);

  line-height: inherit;

  &:hover {
    color: var(--ring-link-hover-color);
  }
}

.branchIcon {
  color: inherit;
}
