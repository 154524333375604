@value unit: var(--ring-unit);

.branchLabel {
  padding: 0;

  background-color: transparent;

  &:hover,
  &:focus {
    color: var(--ring-link-hover-color);
    background-color: transparent;
  }

  & svg {
    margin-right: calc(unit / 2);
  }
}

.branchLabelName {
  overflow: hidden;
}
