@value unit: var(--ring-unit);

.icon {
  color: var(--ring-icon-color);

  & svg {
    width: calc(unit * 2);
    height: calc(unit * 2);
  }
}

.button {
  composes: job from '../../../components/JobTile/JobTile.css';

  margin: 0;

  cursor: pointer;
  text-align: left;

  border: 1px dashed var(--ring-borders-color);

  background-color: var(--ring-content-background-color);

  font-weight: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &:hover {
    color: var(--ring-link-hover-color);
    border-color: var(--ring-link-hover-color);

    & .icon {
      color: var(--ring-link-hover-color);
    }
  }

  &[disabled] {
    pointer-events: none;

    opacity: 0.7;

    color: var(--ring-secondary-color);
  }
}
