@value unit: var(--ring-unit);

.button {
  margin-right: calc(unit * -1);
}

.popupBubble {
  box-sizing: border-box;
  min-height: calc(unit * 3);
  padding-right: unit;
  padding-left: unit;

  border: 1px solid var(--ring-popup-border-color);
  border-radius: var(--ring-border-radius);

  background: var(--ring-popup-background-color);
  box-shadow: var(--ring-popup-shadow);

  font-size: 12px;
  line-height: 22px;
}
