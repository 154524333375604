@value unit: var(--ring-unit);
@value button-shadow from '@jetbrains/ring-ui/components/button/button.css';

.hidden {
  visibility: hidden;

  opacity: 0;
}

.in {
  transition: var(--ring-ease);
}

.out {
  transition: var(--ring-fast-ease);
}

.secret {
  position: absolute;

  top: unit;
  left: calc(unit * 1.5);
}

.container {
  position: relative;

  padding: unit calc(unit * 1.5);

  cursor: pointer;

  &:focus-visible {
    outline: none;
    box-shadow: button-shadow var(--ring-border-hover-color);
  }
}
